<div class="madero-checkbox">
  <input type="checkbox" id="{{checkboxId}}" name="{{checkboxId}}" [checked]="ngModel || ngValue" tabindex="0" [attr.disabled]="disabled ? '' : null">
  <label>
    <streamline-icon
      name="checkmark"
      width="14"
      height="12"
      [ariaLabel]="ariaLabel">
    </streamline-icon>
  </label>
</div>
