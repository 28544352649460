import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CoreAPILoader, StoreAPILoader } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class StoreApiService {

  constructor(
    private httpClient: HttpClient,
    private coreAPILoader: CoreAPILoader,
    private storeAPILoader: StoreAPILoader) { }

  _getResult (resp) {
    if (resp.result !== null && typeof resp.result === 'object') {
      return resp.result;
    } else {
      return resp;
    }
  };

  validateAddress (addressObject) {
    var obj = {
      'street': addressObject.street,
      'unit': addressObject.unit,
      'city': addressObject.city,
      'country': addressObject.country,
      'postalCode': addressObject.postalCode,
      'province': addressObject.province,
    };

    return this.storeAPILoader()
      .then(storeAPI => {
        return storeAPI.company.validateAddress(obj);
      })
      .then(resp => {
        var result = this._getResult(resp);
        console.debug('validateAddress result: ', result);

        if (result.code !== -1) {
          return result;
        } else {
          return Promise.reject(result);
        }
      });
  }

  estimatePurchase (jsonData) {
    var obj = {
      jsonData
    };

    return this.storeAPILoader()
      .then(storeAPI => {
        return storeAPI.purchase.estimate(obj);
      })
      .then(resp => {
        if (resp && resp.result && resp.result.items) {
          console.debug('tax estimate resp', resp);
          return resp.result;
        } else {
          console.error('Failed to get tax estimate.', resp.result);

          return Promise.reject();
        }
      })
      .catch(resp => {
        console.error('Failed to get tax estimate.', resp);

        return Promise.reject(resp && resp.result && resp.result.error);
      });
  }

  preparePurchase (jsonData) {
    return this.storeAPILoader()
      .then(storeAPI => {
        var obj = {
          jsonData: jsonData
        };
        return storeAPI.purchase.prepare(obj);
      })
      .then(resp => {
        if (resp && resp.result) {
          console.debug('prepare purchase resp', resp);
          return resp.result;
        } else {
          return Promise.reject();
        }
      })
      .catch(resp => {
        console.error('Failed to prepare Purchase.', resp);

        return Promise.reject(resp && resp.result && resp.result.error);
      });
  }

  purchase (jsonData) {
    return this.storeAPILoader()
      .then(storeAPI => {
        var obj = {
          jsonData: jsonData
        };
        return storeAPI.purchase.put(obj);
      })
      .then(resp => {
        if (resp && resp.result) {
          console.debug('purchase resp', resp);
          return resp.result;
        } else {
          return Promise.reject();
        }
      })
      .catch(resp => {
        console.error('Failed to get Purchase.', resp);

        return Promise.reject(resp && resp.result && resp.result.error);
      });
  }

  preparePayment (paymentMethodId, invoiceId, companyId, token) {
    return this.storeAPILoader()
      .then(storeAPI => {
        var obj = {
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
          companyId: companyId,
          token: token
        };
        return storeAPI.payment.prepare(obj);
      })
      .then(resp => {
        if (resp && resp.result) {
          console.debug('prepare payment resp', resp);
          return resp.result;
        } else {
          return Promise.reject();
        }
      })
      .catch(resp => {
        console.error('Failed to prepare Payment.', resp);

        return Promise.reject(resp && resp.result && resp.result.error);
      });
  }

  collectPayment (paymentIntentId, invoiceId, companyId, token) {
    return this.storeAPILoader()
      .then(storeAPI => {
        var obj = {
          paymentIntentId: paymentIntentId,
          invoiceId: invoiceId,
          companyId: companyId,
          token: token
        };
        return storeAPI.payment.collect(obj);
      })
      .then(resp => {
        if (resp && resp.result) {
          console.debug('purchase resp', resp);
          return resp.result;
        } else {
          return Promise.reject();
        }
      })
      .catch(resp => {
        console.error('Failed to get Purchase.', resp);

        return Promise.reject(resp && resp.result && resp.result.error);
      });
  }

  addTaxExemption (companyId, taxExemption, blobKey) {
    // var expiryDateString = $filter('date')(taxExemption.expiryDate, 'yyyy-MM-dd');

    return this.coreAPILoader()
      .then(function (coreAPI) {
        var obj = {
          'companyId': companyId,
          // 'country': taxExemption.country,
          // 'state': taxExemption.province,
          'blobKey': blobKey,
          'number': taxExemption.number,
          // 'expiryDate': expiryDateString
        };

        return coreAPI.taxExemption.add(obj);
      })
      .then(resp => {
        if (resp.error) {
          console.error('Error adding tax exemption: ', resp.message);
          return Promise.reject(resp.error);
        } else {
          return resp;
        }
      });
  }

  uploadTaxExemptionCertificate (file) {
    var formData = new FormData();

    formData.append('file', file);

    return this.coreAPILoader()
      .then(function (coreAPI) {
        return coreAPI.taxExemption.getUploadUrl();
      })
      .then(resp => {
        if (resp.error) {
          console.error('Error getting upload url: ', resp.message);
          return Promise.reject(resp.error);
        } else {
          return firstValueFrom(
            this.httpClient.post(resp.result.item, formData, {
              withCredentials: true,
              responseType: 'text'
            }))
            .then((response: any) => {
              return response;
            })
            .catch(error => {
              console.error('Error uploading file: ', error);
              return Promise.reject(error);
            });
        }
      });
  }

}
